import { Box, MenuItem, Typography } from "@mui/material";
import { notificationComponentStyles } from "./hrComponentsStyles";
import MessageIcon from "@mui/icons-material/Message";
import Avatar from "@mui/material/Avatar";

const NotificationComponent = props => {
  const classes = notificationComponentStyles();
  const { handleClose } = props;

  return (
    <MenuItem onClick={handleClose}>
      <Box className={classes.outer}>
        <Box className={classes.iconContainer}>
          <Avatar classes={{ root: classes.iconContainerAvatar }}>
            <MessageIcon color="white" />
          </Avatar>
        </Box>
        <Box className={classes.notificationBody}>
          <Typography variant="h2">Check in</Typography>
          <Typography variant="body">
            Request for a diagloue regarding "Project Name"
          </Typography>
        </Box>
        <Box className={classes.iconSuffix}>
          <Typography variant="h5">10:05am</Typography>
        </Box>
      </Box>
    </MenuItem>
  );
};

export default NotificationComponent;
