import { Box, Input, MenuItem, Select, Typography } from "@mui/material";
import { dayOfWeekComponentStyles } from "./hrComponentsStyles";
import { useEffect } from "react";
import { getQuestions, updateQuestion } from "../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";

const DayOfWeekComponent = props => {
  const classes = dayOfWeekComponentStyles();
  const {
    dayOfWeek,
    activeQuestions,
    updateQuestionList,
    localQuestions,
    setLocalQuestions,
    questionType
  } = props;
  const { user } = useAuth0();
  const currentSelectedQuestion = activeQuestions.find(
    q => q.day === dayOfWeek
  );
  useEffect(() => {
    setLocalQuestions(prev => {
      return {
        ...prev,
        [dayOfWeek]: currentSelectedQuestion
          ? currentSelectedQuestion._id
          : null
      };
    });
  }, []);

  const handleSelect = selected => {
    updateQuestion(null, selected, {
      day: dayOfWeek,
      requestingUserId: user.sub
    }).then(() => {
      getQuestions({ externalId: user.sub }).then(q => {
        // updateQuestionList(q.questions);
      });
    });
  };
  return (
    <Box className={classes.outer}>
      <Typography>
        {dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)}
      </Typography>
      <Select
        input={
          <Input
            sx={{
              padding: "0.8rem",
              fontSize: "18px",
              fontWeight: 300,
              color: "black"
            }}
            classes={{
              input: "hide-icon"
            }}
          />
        }
        variant="standard"
        disableUnderline={true}
        value={localQuestions[dayOfWeek] ? localQuestions[dayOfWeek] : "-"}
        disabled={questionType === "random"}
        onChange={e => {
          handleSelect(e.target.value);
          setLocalQuestions(prev => {
            const currentlyUsed = Object.keys(prev).find(
              key => prev[key] === e.target.value
            );
            let newData = {
              ...prev,
              [dayOfWeek]: e.target.value
            };
            if (currentlyUsed) {
              newData[currentlyUsed] = null;
            }
            return newData;
          });
        }}
      >
        <MenuItem value="-">No Question Set</MenuItem>
        {activeQuestions.map(a => (
          <MenuItem value={a._id}>{a.question}</MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default DayOfWeekComponent;
