import { makeStyles } from "@mui/styles";

export const completedPieStyles = makeStyles({
  participationOuter: {
    backgroundColor: "white",
    padding: "2rem",
    borderRadius: "1rem",
    maxHeight: 300,
    boxShadow: "2px 5px 5px 1px rgba(184,184,184,0.52)",
    margin: "0.8rem"
    // maxHeight: 300
  },
  participationInner: {
    display: "flex",
    flexDirection: "column"
    // justifyContent: "center",
    // alignItems: "center"
  },
  boxInformation: {
    display: "flex",
    flexDirection: "column"
  },
  informationOuter: {
    width: 500,
    "@media (max-width: 1500px)": {
      width: 380
    },
    display: "flex",
    alignItems: "center"
  },
  headingRow: {
    display: "flex"
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  legendBubble: {
    backgroundColor: "#A7C4A1",
    margin: "0.4rem",
    width: "1rem",
    height: "1rem",
    borderRadius: "50%"
  }
});

export const userListStyles = makeStyles({
  participationOuter: {
    width: 500,
    marginRight: "2rem"
  },

  participationInner: { maxHeight: 300, overflowY: "auto" },
  informationOuter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  nameAvatarContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiTypography-h3": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      padding: "1rem"
    }
  },
  staffPersonRow: {
    cursor: "pointer"
  }
});

export const notificationComponentStyles = makeStyles({
  outer: {
    display: "flex",
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#494949"
    },
    "& .MuiTypography-body": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 300
    }
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "0.2rem",
    paddingRight: "1.2rem"
  },
  iconContainerAvatar: {
    height: "3rem !important",
    width: "3rem !important",
    backgroundColor: "#A7C4A1 !important"
  },
  notificationBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  iconSuffix: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "1.2rem",
    "& .MuiTypography-h5": {
      margin: 0,
      fontSize: "0.8rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#494949"
    }
  }
});

export const questionComponentStyles = makeStyles({
  outer: {
    backgroundColor: "white",
    borderRadius: "0.2rem",
    padding: "1rem",
    display: "flex",
    marginTop: "0.8rem",
    marginBottom: "0.8rem",
    width: "100%",
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#494949"
    },
    "& .MuiTypography-body": {
      margin: 0,
      fontSize: "1rem",
      fontFamily: "League Spartan,regular",
      fontWeight: 400
    }
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "0.2rem",
    paddingRight: "1.2rem"
  },
  iconContainerAvatar: {
    height: "3rem !important",
    width: "3rem !important",
    backgroundColor: "#A7C4A1 !important"
  },
  notificationBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "80%"
  },
  iconSuffixType: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginLeft: "1.2rem",
    width: 180,
    "& .MuiTypography-h5": {
      margin: 0,
      fontSize: "0.8rem",
      fontWeight: 800,
      // textTransform: "uppercase",
      color: "#494949"
    }
  },
  iconSuffix: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "1.2rem",
    width: 180,
    "& .MuiTypography-h5": {
      margin: 0,
      fontSize: "0.8rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#494949"
    }
  }
});
export const dayOfWeekComponentStyles = makeStyles({
  outer: {
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "0.2rem",
    padding: "1rem",
    display: "flex",
    marginTop: "0.8rem",
    marginBottom: "0.8rem",
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#494949"
    },
    "& .MuiTypography-body": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 300
    }
  }
});
export const questionComponentHeadingStyles = makeStyles({
  outer: {
    borderRadius: "1rem",
    width: "100%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    display: "flex",
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#494949"
    },
    "& .MuiTypography-body": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 300
    }
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "90%"
  },
  iconSuffix: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "1.2rem",
    width: 180,
    "& .MuiTypography-h5": {
      margin: 0,
      fontSize: "0.8rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#494949"
    }
  },
  iconSuffixType: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginLeft: "1.2rem",
    paddingRight: "1.8rem",
    width: 180,
    "& .MuiTypography-h5": {
      margin: 0,
      fontSize: "0.8rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#494949"
    }
  }
});

export const moodTrendStyles = makeStyles({
  outer: {
    maxHeight: 300,
    backgroundColor: "white",
    padding: "2rem",
    borderRadius: "1rem",
    maxHeight: 300,
    boxShadow: "2px 5px 5px 1px rgba(184,184,184,0.52)",
    margin: "0.8rem"
  },
  inner: {
    display: "flex",
    flexDirection: "column"
    // justifyContent: "center",
    // alignItems: "center"
  },
  informationOuter: {
    height: 300,
    width: 500,
    "@media (max-width: 1500px)": {
      width: 380
    },
    display: "flex",
    alignItems: "center"
  },
  headingRow: {
    display: "flex"
  }
});

export const participationTrendStyle = makeStyles({
  outer: {
    backgroundColor: "white",
    padding: "2rem",
    borderRadius: "1rem",
    maxHeight: 300,
    boxShadow: "2px 5px 5px 1px rgba(184,184,184,0.52)",
    margin: "0.8rem"
  },
  inner: {
    display: "flex",
    flexDirection: "column"
    // justifyContent: "center",
    // alignItems: "center"
  },
  informationOuter: {
    height: 300,
    width: 500,
    "@media (max-width: 1500px)": {
      width: 380
    }
  },
  headingRow: {
    display: "flex"
  }
});

export const navBarStyles = makeStyles({
  navBar: {
    minWidth: 200,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#A7C4A1",
    "& .MuiTypography-h2": {
      fontSize: "1.2rem",
      fontWeight: "800",
      marginLeft: "0.8rem",
      marginRight: "0.8rem",
      color: "white"
    }
  },
  navItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "1rem",
    paddingRight: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "white",
      "& .MuiTypography-h2": {
        color: "#A7C4A1"
      }
    }
  },
  menuItemsContainer: {},
  navBarTopContainer: {
    marginTop: "0.8rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "& .MuiTypography-h3": {
      fontSize: "1.2rem",
      fontWeight: "400",
      marginLeft: "0.8rem",
      marginRight: "0.8rem",
      color: "white"
    }
  },
  navBarBottomContainer: { height: "4rem" },
  iconContainer: {
    padding: "0.1rem"
  }
});

export const personComponentStyles = makeStyles({
  outer: {
    zIndex: 999,
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    display: "flex",
    marginTop: "0.5rem",
    backgroundColor: "white",
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#494949"
    },
    "& .MuiTypography-body": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 300
    },
    justifyContent: "space-between"
    // "@media (max-width: 1100px)": {
    //   flexDirection: "column"
    // }
  },
  left: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  right: {
    display: "flex",
    alignItems: "center"
  },
  statisticsButton: {
    width: 100,
    marginLeft: "1rem",
    marginRight: "1rem",
    padding: "0.8rem",
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    borderRadius: 18,
    textAlign: "center"
  },
  lowParticipation: {
    backgroundColor: "#ffcaca"
  },
  highParticipation: {
    backgroundColor: "#c3dec2"
  },
  name: {
    marginLeft: "0.8rem !important",
    marginRight: "0.8rem !important"
  },
  hamburgerMenuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiTypography-body2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,

      color: "#474747"
    }
  },
  deactivateModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 310,
    backgroundColor: "white",
    padding: "1.5rem",
    paddingTop: "1.2rem",
    paddingBottom: "1.2rem",
    borderRadius: 24,
    boxShadow: 24
  },
  modalInner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
    // alignItems: "center"
  },
  modalButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%"
  }
});

export const personComponentHeaderStyles = makeStyles({
  outer: {
    display: "flex",
    alignItems: "center",
    minWidth: 800,
    paddingLeft: "1rem",
    paddingRight: "1rem",
    display: "flex",

    "& .MuiTypography-body2": {
      margin: 0,
      fontSize: "0.8rem",
      fontWeight: 800,
      textTransform: "uppercase",
      color: "#474747"
    },
    justifyContent: "space-between"
  },
  left: {},
  right: {
    display: "flex",
    alignItems: "center",
    marginRight: 24
  },
  participationHeading: {
    marginLeft: "1rem",
    marginRight: "1rem",
    padding: "0.8rem",
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    width: 100
  },
  lowParticipation: {
    backgroundColor: "#ffcaca"
  },
  averageParticipation: {
    backgroundColor: "#f1efac"
  },
  highParticipation: {
    backgroundColor: "#c3dec2"
  },
  name: {
    marginLeft: "0.8rem !important",
    marginRight: "0.8rem !important"
  }
});
