import { makeStyles } from "@mui/styles";

export const statusComponentStyles = makeStyles({
  outerContainer: {
    display: "flex",
    backgroundColor: "white",
    marginTop: "0.8rem",
    padding: "0.8rem"
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    width: 150
  },
  valueContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem",
    paddingBottom: 0
  },
  trendValue: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center"
  },
  iconPositive: {
    marginTop: -1,
    color: "green"
  },
  iconNegative: {
    marginTop: -1,
    color: "red"
  },
  "& .MuiTypography-h2": {
    margin: 0,
    fontSize: "1.2rem",
    fontWeight: 400,
    color: "#494949"
  }
});
