import { Box, Typography } from "@mui/material";
import ResponsesLogItem from "./ResponsesLogItem";
import { responsesLogStyles } from "./dashboardComponentStyles";

const ResponsesLog = () => {
  const classes = responsesLogStyles();
  return (
    <Box className={classes.outerContainer}>
      <Typography variant="h3">Responses</Typography>
      <ResponsesLogItem />
      <ResponsesLogItem />
      <ResponsesLogItem />
      <ResponsesLogItem />
    </Box>
  );
};

export default ResponsesLog;
