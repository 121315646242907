import { helpContainerComponentStyles } from "./componentsStyles";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

const AvailableTimesComponent = props => {
  const classes = helpContainerComponentStyles();
  const {
    time,
    selectedSlot,
    changeSelectedSlot,
    bookingData,
    changeBookingData
  } = props;

  return (
    <Box
      className={
        time === selectedSlot
          ? classes.appointmentSelected
          : classes.appointment
      }
      onClick={() => {
        changeBookingData({ ...bookingData, bookingTime: time });
        changeSelectedSlot(time);
      }}
    >
      <Typography variant="h2">
        {dayjs(time, "H:mm").format("h:mm a")}
      </Typography>
      <Typography variant="body1">15 minutes</Typography>
    </Box>
  );
};

export default AvailableTimesComponent;
