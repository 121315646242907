import { Box, Typography } from "@mui/material";
import { moodTrendStyles } from "./personUserStyles";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ResponsiveBar } from "@nivo/bar";
import { getGraphData } from "../../apiHelper";

const PersonMoodTrend = props => {
  const classes = moodTrendStyles();

  const { range, personId } = props;
  const { getAccessTokenSilently } = useAuth0();

  const [graphData, setGraphData] = useState([
    { x: "monday", y: 0 },
    { x: "tuesday", y: 0 },
    { x: "wednesday", y: 0 },
    { x: "thursday", y: 0 },
    { x: "friday", y: 0 },
    { x: "saturday", y: 0 },
    { x: "sunday", y: 0 }
  ]);

  useEffect(() => {
    getAccessTokenSilently().then(t =>
      getGraphData(t, {
        personId,
        graphType: "person-mood-trend",
        range: range
      }).then(data => {
        setGraphData(data);
      })
    );
  }, [range]);

  return (
    <Box className={classes.outer}>
      <Typography variant="h2">Ratings Trend</Typography>
      <Box className={classes.inner}>
        <Box className={classes.informationOuter}>
          <ResponsiveBar
            data={graphData}
            keys={["y"]}
            indexBy="x"
            margin={{ top: 10, right: 10, bottom: 50, left: 30 }}
            padding={0.3}
            colors={"#9FC59E"}
            maxValue={10}
            minValue={0}
            gridYValues={4}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]]
            }}
            enableLabel={false}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: 32
            }}
            axisLeft={{
              tickValues: 4,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -40
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PersonMoodTrend;
