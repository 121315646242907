import { Box, Typography } from "@mui/material";
import { statusComponentStyles } from "./personPageComponentsStyles";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const StatusComponent = props => {
  //general feel
  //today's score
  //average over this week score
  //average over this month score
  const classes = statusComponentStyles();
  const { scoreToday, monthlyAverage, participation, trend } = props;
  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.itemContainer}>
        <Box>
          <Typography>Trend</Typography>
        </Box>
        <Box className={classes.valueContainer}>
          <Typography className={classes.trendValue} variant="h2">
            {trend === "Positive" ? (
              <KeyboardDoubleArrowUpIcon className={classes.iconPositive} />
            ) : (
              <KeyboardDoubleArrowDownIcon className={classes.iconNegative} />
            )}
            {trend}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.itemContainer}>
        <Box>
          <Typography>Participation</Typography>
        </Box>
        <Box className={classes.valueContainer}>
          <Typography variant="h2">{participation}</Typography>
        </Box>
      </Box>
      <Box className={classes.itemContainer}>
        <Box>
          <Typography>Today</Typography>
        </Box>
        <Box className={classes.valueContainer}>
          <Typography variant="h2">{scoreToday ? scoreToday : "-"}</Typography>
        </Box>
      </Box>
      <Box className={classes.itemContainer}>
        <Box>
          <Typography>Monthly</Typography>
        </Box>
        <Box className={classes.valueContainer}>
          <Typography variant="h2">
            {monthlyAverage ? monthlyAverage : "-"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StatusComponent;
