import { makeStyles } from "@mui/styles";

export const personUserDetailsStyles = makeStyles({
  outerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "0.6rem",
    "& .MuiTypography-body1": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949"
    },
    "& .MuiTypography-h3": {
      margin: 0,
      fontSize: "1.2rem",
      fontWeight: 600,
      color: "#494949"
    }
  },
  detailUnit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  dot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "0.6rem",
    marginRight: "0.6rem"
  }
});

export const commentAnswersStyles = makeStyles({
  outerContainer: {
    display: "flex",
    marginTop: "2rem",
    padding: "2rem",
    paddingLeft: 0,
    flexDirection: "column",
    alignItems: "flex-start",
    width: "50%",
    "& .MuiTypography-h3": {
      margin: 0,
      marginBottom: "1rem",
      fontSize: "1.2rem",
      fontWeight: 600,
      color: "#494949"
    }
  },
  innerContainer: {
    overflow: "auto",
    height: 400,
    width: "100%"
  }
});
export const otherCommentStyles = makeStyles({
  outerContainer: {
    display: "flex",
    marginTop: "2rem",
    padding: "2rem",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "50%",
    "& .MuiTypography-h3": {
      margin: 0,
      marginBottom: "1rem",
      fontSize: "1.2rem",
      fontWeight: 600,
      color: "#494949"
    }
  },
  innerContainer: {
    overflow: "auto",
    height: 400,
    width: "100%"
  }
});
export const commentItemStyles = makeStyles({
  outerContainer: {
    display: "flex",
    marginTop: "0.4rem",
    padding: "1rem",
    width: "92%",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: "white",
    wordBreak: "break-all",
    "& .MuiTypography-body1": {
      margin: 0,
      marginBottom: 2,
      fontSize: "1.2rem",
      fontWeight: 400,
      fontStyle: "italic",
      color: "#494949"
    },
    "& .MuiTypography-body2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949"
    }
  }
});

export const personNoCommentItemStyles = makeStyles({
  outerContainer: {
    display: "flex",
    marginTop: "0.4rem",
    padding: "1rem",
    width: "92%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    "& .MuiTypography-body1": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      color: "#919191"
    }
  }
});

export const moodTrendStyles = makeStyles({
  outer: {
    maxHeight: 300,
    margin: "0.8rem",
    marginTop: "1.6rem",
    marginBottom: "1.6rem",
    width: "50%"
  },
  inner: {
    padding: "1.6rem",
    paddingBottom: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column"
    // justifyContent: "center",
    // alignItems: "center"
  },
  informationOuter: {
    height: 300,
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  headingRow: {
    display: "flex"
  }
});

export const participationTrendStyle = makeStyles({
  outer: {
    maxHeight: 300,
    margin: "0.8rem",
    marginBottom: "1.6rem",
    marginLeft: 0,
    marginTop: "1.6rem",
    width: "50%"
  },
  inner: {
    padding: "1.6rem",
    paddingBottom: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column"
    // justifyContent: "center",
    // alignItems: "center"
  },
  informationOuter: {
    height: 300,
    width: "100%"
  },
  headingRow: {
    display: "flex"
  }
});
