import { Modal, Box, Typography, Button } from "@mui/material";
import { rescheduleModalStyles } from "./bookingsComponentStyles";
import HelpDateComponent from "../../components/HelpDateComponent";
import { useState } from "react";
import { updateRescheduleBooking } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmReschedule from "./ConfirmReschedule";

const RescheduleModal = (props) => {
  const { modalOpen, openModal, bookingId, bookingMakerName, recipientEmail } =
    props;
  const { getAccessTokenSilently, user } = useAuth0();
  const [bookingData, changeBookingData] = useState({});
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [rescheduleResponse, setRescheduleResponse] = useState({});
  const handleReschedule = () => {
    setLoading(true);
    setClicked(true);
    getAccessTokenSilently().then((t) => {
      updateRescheduleBooking(t, bookingId, {
        ...bookingData,
        bookingMakerName,
        recipientEmail,
        confirmed: true,
        providerName: user.given_name,
      }).then((rescheduleData) => {
        setLoading(false);
        setRescheduleResponse(rescheduleData);
      });
    });
  };

  return (
    <Modal open={modalOpen} onClose={() => openModal(false)}>
      {clicked ? (
        <ConfirmReschedule
          loading={loading}
          openModal={openModal}
          rescheduleResponse={rescheduleResponse}
          setClicked={setClicked}
          setCancelResponse={setRescheduleResponse}
        />
      ) : (
        <ModalContent
          openModal={openModal}
          handleReschedule={handleReschedule}
          bookingData={bookingData}
          changeBookingData={changeBookingData}
        />
      )}
    </Modal>
  );
};

const ModalContent = (props) => {
  const { openModal, handleReschedule, bookingData, changeBookingData } = props;

  const modalClasses = rescheduleModalStyles();

  return (
    <Box className={modalClasses.modalBase}>
      <Typography variant="h1">Suggest a new time</Typography>
      <HelpDateComponent
        suggest={true}
        bookingData={bookingData}
        changeBookingData={changeBookingData}
      />
      <Box className={modalClasses.buttonRow}>
        <Button
          onClick={() => {
            handleReschedule();
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            openModal(false);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default RescheduleModal;
