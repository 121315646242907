import { Box, Typography } from "@mui/material";
import { personNoCommentItemStyles } from "../hrDashboardComponents/personComponents/personUserStyles";

export const NoCommentItem = () => {
  const classes = personNoCommentItemStyles();
  return (
    <Box className={classes.outerContainer}>
      <Typography variant="body1">There are no entries this week.</Typography>
    </Box>
  );
};
