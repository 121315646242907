import { useNavigate } from "react-router-dom";
import { somethingElsePageStyles } from "./pagesStyles";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { addComments } from "../apiHelper";

function SomethingElse() {
  const classes = somethingElsePageStyles();
  const history = useNavigate();
  const { user, getAccessTokenSilently } = useAuth0();
  const [value, setValue] = useState("");

  const determineNavigate = () => {
    getAccessTokenSilently()
      .then((t) => {
        addComments(t, {
          createdAt: new Date(),
          value,
          externalId: user.sub,
        });
      })
      .then(() => {
        history("/comment-success");
      });
  };

  return (
    <Box className={classes.backdrop}>
      <Box className={classes.outerContainer}>
        <Box className={classes.titleContainer}>
          <Typography variant="h1">Talk to us...</Typography>
        </Box>
        <TextField
          id="something-else-input"
          // label="Multiline"
          placeholder="Let us know what you're thinking about..."
          fullWidth
          multiline
          rows={4}
          onChange={(e) => setValue(e.target.value)}
        />
        <Box className={classes.buttonContainer}>
          <Button
            onClick={() => {
              history("/help", {
                state: {
                  questionId: null,
                  score: null,
                  somethingElse: true,
                },
              });
            }}
          >
            <Typography variant="body2">
              Book a time to talk with someone
            </Typography>
          </Button>
          <Box className={classes.buttonContainerRight}>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: "0.4rem",
                borderRadius: "20px",

                height: "2rem",
              }}
              color="primary"
              onClick={() => history("/")}
            >
              <Typography variant="body1">Cancel</Typography>
            </Button>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: "0.4rem",
                borderRadius: "20px",
                backgroundColor: "#A7C4A1",
                height: "2rem",
              }}
              color="primary"
              variant="contained"
              onClick={determineNavigate}
            >
              <Typography variant="body1">Submit</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SomethingElse;
