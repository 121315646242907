import { makeStyles } from "@mui/styles";

export const profileUserDetailsStyles = makeStyles({
  outerContainer: {},
  nameAndPictureContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  iconContainerAvatar: {
    height: "5rem !important",
    width: "5rem !important",
    backgroundColor: "#A7C4A1 !important"
  },
  innerText: { padding: "1rem" },
  nameText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.6rem",
      fontWeight: 800,
      color: "#494949"
    },
    "& .MuiTypography-h3": {
      margin: 0,
      fontSize: "1.2rem",
      fontWeight: 400,
      color: "#494949"
    }
  }
});
