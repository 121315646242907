import { helpContainerComponentStyles } from "./componentsStyles";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

const UnavailableComponent = props => {
  const classes = helpContainerComponentStyles();

  return (
    <Box className={classes.appointment}>
      <Typography variant="body1">No times available on this day.</Typography>
    </Box>
  );
};

export default UnavailableComponent;
