import { Box, Typography } from "@mui/material";
import { availableDays } from "./bookingsComponentStyles";
import { TimePicker } from "antd";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import dayjs from "dayjs";

const TimeRangeSelectorComponent = props => {
  const classes = availableDays();
  const { availableData, changeAvailableData, slot, index, day, setChanged } =
    props;

  const format = "h:mm a";

  const deleteFromData = () => {
    availableData[day].splice(index, 1);
    changeAvailableData({ ...availableData });
    setChanged(true);
  };

  return (
    <Box className={classes.timeBoxInner}>
      <TimePicker
        format={format}
        value={
          availableData[day][index].fromTime
            ? dayjs(availableData[day][index].fromTime, "H:mm")
            : null
        }
        onChange={a => {
          availableData[day].splice(index, 1, {
            fromTime: dayjs(a).format("H:mm"),
            toTime: availableData[day][index].toTime
          });
          changeAvailableData({
            ...availableData,
            [day]: [...availableData[day]]
          });
          setChanged(true);
        }}
      />
      <Box className={classes.timeSeparatorContainer}>
        <Typography variant="body">to</Typography>
      </Box>
      <TimePicker
        format={format}
        value={
          availableData[day][index].toTime
            ? dayjs(availableData[day][index].toTime, "H:mm")
            : null
        }
        onChange={a => {
          availableData[day].splice(index, 1, {
            fromTime: availableData[day][index].fromTime,
            toTime: dayjs(a).format("H:mm")
          });
          changeAvailableData({
            ...availableData,
            [day]: [...availableData[day]]
          });
          setChanged(true);
        }}
      />
      <Box className={classes.timeSeparatorContainer}>
        <HighlightOffIcon onClick={deleteFromData} />
      </Box>
    </Box>
  );
};

export default TimeRangeSelectorComponent;
