import { Box, Typography } from "@mui/material";
import { personComponentHeaderStyles } from "./hrComponentsStyles";
import { useParams } from "react-router-dom";

const PersonComponentHeader = props => {
  const classes = personComponentHeaderStyles();
  const {} = props;
  const params = useParams();

  return (
    <Box className={classes.outer}>
      <Box className={classes.left}></Box>
      <Box className={classes.right}>
        <Box className={classes.participationHeading}>
          <Typography variant="body2">Last Answered</Typography>
        </Box>
        <Box className={classes.participationHeading}>
          <Typography variant="body2">Participation</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonComponentHeader;
