import { helpContainerComponentStyles } from "./componentsStyles";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

const HelpContainerComponent = props => {
  const classes = helpContainerComponentStyles();
  const { handleSubmit, bookingData, changeBookingData } = props;

  return (
    <Box className={classes.helpContainerOuter}>
      <Typography variant="h2">Tell us what you want to talk about</Typography>
      <TextField
        id="outlined-multiline-flexible"
        // label="Multiline"
        placeholder="If you don't want to share just yet, you can leave this empty!"
        fullWidth
        multiline
        rows={4}
        onChange={e =>
          changeBookingData({ ...bookingData, comments: e.target.value })
        }
      />
      <Box className={classes.helpSubmitContainer}>
        <FormGroup>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={bookingData.anonymous}
                onChange={e => {
                  changeBookingData({
                    ...bookingData,
                    anonymous: !bookingData.anonymous
                  });
                }}
              />
            }
            label="Anonymous"
          /> */}
        </FormGroup>
        <Button variant="contained" onClick={() => handleSubmit()}>
          Submit
        </Button>
      </Box>
    </Box>
  );

  // return (
  //   <Box className={classes.helpContainerOuter}>
  //     <Typography variant="body1">
  //       If you would prefer to chat anonymously, we can still organise an
  //       anonymous chat platform to discuss your well-being. Simply book through
  //       the same booking system on the left and select ‘stay anonymous’ when
  //       asking for your employee details. A link will then be forwarded to your
  //       email where you will be able to attend an anonymous meeting via text or
  //       voice call.
  //     </Typography>
  //   </Box>
  // );
};

export default HelpContainerComponent;
