import { makeStyles } from "@mui/styles";

export const specificDateItemStyles = makeStyles({
  selectContainerOuter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0.8rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginTop: "1rem",
    "& .MuiTypography-h2": {
      margin: 0,
      marginTop: "0.2rem",
      fontSize: "1.1rem",
      fontWeight: 400
    }
  },
  selectContainerInnerLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    "& .MuiTypography-body1": {
      margin: 0,
      marginTop: "0.2rem",
      fontSize: "1.1rem",
      fontWeight: 400
    }
  },
  hamburgerMenuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiTypography-body2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,

      color: "#474747"
    }
  }
});
