import { Avatar, Box, Typography } from "@mui/material";
import { responsesLogItemStyles } from "./dashboardComponentStyles";

const ResponsesLogItem = () => {
  const classes = responsesLogItemStyles();
  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.avatarContainer}>
        <Avatar>HI</Avatar>
      </Box>
      <Box className={classes.details}>
        <Typography>Anonymous Person 1</Typography>
        <Typography>
          Last week I was feeling overwhelmed with the amount of work I was
          given.
        </Typography>
      </Box>
    </Box>
  );
};

export default ResponsesLogItem;
