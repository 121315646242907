import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { rescheduleModalStyles } from "./bookingsComponentStyles";

const ConfirmReschedule = (props) => {
  const modalClasses = rescheduleModalStyles();
  const {
    loading,
    rescheduleResponse,
    openModal,
    setClicked,
    setCancelResponse,
  } = props;
  return (
    <Box className={modalClasses.modalBase}>
      <Box className={modalClasses.progressContainer}>
        {loading ? (
          <CircularProgress />
        ) : rescheduleResponse.rescheduleEmailSent ? (
          <CheckCircle />
        ) : (
          <Cancel />
        )}
      </Box>
      <Typography variant="body1">
        {loading
          ? "Rescheduling"
          : rescheduleResponse.rescheduleEmailSent
          ? "Reschedule confirmation email sent!"
          : "Unable to send reschedule email. Please try again!"}
      </Typography>
      <Box className={modalClasses.buttonRow}>
        <Button
          sx={{ textTransform: "none" }}
          color="primary"
          onClick={() => {
            openModal(false);
            setClicked(false);
            setCancelResponse({});
          }}
        >
          <Typography variant="body2">Done</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmReschedule;
