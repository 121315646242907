import { makeStyles } from "@mui/styles";

export const bookingsList = makeStyles({
  outerContainer: {
    width: "calc(100% - 2rem)",
    marginTop: "0.4rem",
    padding: "1rem",
    "& .MuiTypography-body": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949",
    },
    "& .MuiTypography-h3": {
      margin: 0,
      fontSize: "1.2rem",
      fontWeight: 600,
      color: "#494949",
    },
  },
  newBookingNumberContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "0.8rem",
    backgroundColor: "red",
    borderRadius: "50%",
    height: 12,
    width: 12,
    "& .MuiTypography-h4": {
      margin: 0,
      fontSize: "1.2rem",
      fontWeight: 600,
      color: "#494949",
    },
  },
  subtitleRow: {
    display: "flex",
    justifyContent: "flex-start",
  },

  unconfirmedRow: {
    display: "flex",
    flexDirection: "column",
    marginRight: "1.6rem",
    "@media (min-width: 1350px)": {
      // flexDirection: "row",
    },
  },
  hideArrowContainer: {
    marginLeft: "2rem",
    cursor: "pointer",
  },
  bookingListItemOuter: {
    marginRight: "0.8rem",
  },
  detailUnit: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0.5rem",
  },
  confirmedBookingsContainer: {
    marginLeft: "0.8rem",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    width: "100%",
  },
});
export const bookingsSettings = makeStyles({
  outerContainer: {
    marginTop: "0.4rem",
    padding: "1rem",
    width: "calc(100% - 2rem)",
    "& .MuiTypography-body": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949",
    },
    "& .MuiTypography-h3": {
      margin: 0,
      fontSize: "1.2rem",
      fontWeight: 600,
      color: "#494949",
    },
  },
  availableHoursTitle: {
    display: "flex",
  },
  selectSlotFormContainer: {
    width: 300,
    "& .MuiTypography-body2": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 600,
      color: "#494949",
    },
  },
  formItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailUnit: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0.5rem",
  },
  saveContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
});
export const noBookingsComponent = makeStyles({
  container: {
    display: "flex",
    paddingTop: "1.2rem",
    paddingBottom: "1.2rem",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: "0.8rem",
    "& .MuiTypography-body1": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      color: "#a6a6a6",
    },
  },
});

export const bookingListItem = makeStyles({
  outer: {
    display: "flex",
    borderRadius: "0.8rem",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "1.6rem",
    backgroundColor: "white",
    padding: "1.2rem",
    width: "100%",
    // "@media (min-width: 1350px)": {
    //   width: 290,
    //   marginRight: "1.6rem",
    // },
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.2rem",
      fontWeight: 400,
      color: "#494949",
    },
    "& .MuiTypography-h3": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949",
      lineHeight: 1.5,
    },
    "& .MuiTypography-body1": {
      marginTop: "0.2rem",
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949",
      textAlign: "left",
    },
    "& .MuiTypography-body2": {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949",
    },
  },
  unconfirmedOuter: {
    display: "flex",
    borderRadius: "0.8rem",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "1.6rem",
    backgroundColor: "white",
    padding: "1.2rem",
    width: "100%",
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.2rem",
      fontWeight: 400,
      color: "#494949",
    },
    "& .MuiTypography-h3": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949",
      lineHeight: 1.5,
    },
    "& .MuiTypography-body1": {
      marginTop: "0.2rem",
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949",
      textAlign: "left",
    },
    "& .MuiTypography-body2": {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  dateTimeRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  date: {
    display: "flex",
    justifyContent: "flex-start",
    width: 220,
  },
  bookingName: {
    display: "flex",
    justifyContent: "flex-start",
    width: 220,
  },
  time: {
    display: "flex",
    justifyContent: "flex-start",
    width: 80,
  },
  comments: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    wordBreak: "break-all",
  },
  answerInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: 300,
  },
  dotContainer: {
    display: "flex",
    justifyContent: "center",
    marginLeft: 5,
    marginRight: 5,
  },
  buttonsContainer: {
    marginTop: "1rem",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  buttonOuter: {
    padding: "0.2rem",
  },
  confirmedContainer: {
    display: "flex",
  },
  buttonGroupRight: {
    display: "flex",
  },
});

export const rescheduleModalStyles = makeStyles({
  modalBase: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "1.5rem",
    borderRadius: 24,
    boxShadow: 24,
    "& .MuiTypography-h1": {
      margin: 0,
      marginLeft: "0.2rem",
      marginRight: "0.4rem",
      marginTop: "0.2rem",
      fontSize: "2rem",
      fontWeight: 400,
    },
    // "& .MuiTypography-h4": {
    //   margin: 0,
    //   fontSize: "1rem",
    //   fontWeight: 400,
    //   color: "#cb5050",
    // },
    // "& .MuiTypography-body1": {
    //   margin: 0,
    //   fontSize: "1rem",
    //   fontWeight: 400,
    //   color: "#cb5050",
    // },
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "0.8rem",
  },
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const availableDays = makeStyles({
  outer: {
    display: "flex",
    flexDirection: "row",
    width: "65vw",
    justifyContent: "space-between",
    padding: "0.5rem",
    "& .MuiTypography-body": {
      margin: 0,
      fontSize: "0.8rem",
      fontWeight: 600,
      color: "#494949",
    },
  },
  dayBox: {
    display: "flex",
    alignItems: "center",
    width: 100,
    "& .MuiTypography-h4": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 600,
      color: "#494949",
    },
  },
  timeSeparatorContainer: {
    display: "flex",
    alignItems: "center",
    margin: "0.4rem",
  },
  timeBox: {
    display: "flex",
    alignItems: "center",
    padding: "0.4rem",
    "& .MuiTypography-body2": {
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
  timeBoxInner: {
    display: "flex",
    alignItems: "center",
  },
  addBox: {
    display: "flex",
    alignItems: "center",
    padding: "0.4rem",
    "& .MuiTypography-h4": {
      margin: "0.4rem",
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949",
    },
  },
});
