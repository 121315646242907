import { Box, Typography } from "@mui/material";
import { navBarStyles } from "./hrComponentsStyles";
import SpeedIcon from "@mui/icons-material/Speed";
import PeopleIcon from "@mui/icons-material/People";
import QuizIcon from "@mui/icons-material/Quiz";
import Avatar from "@mui/material/Avatar";
import BookIcon from "@mui/icons-material/Book";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const NavBar = props => {
  const classes = navBarStyles();
  const history = useNavigate();
  const { user, isLoading } = useAuth0();
  const { profile } = props;
  return (
    !isLoading &&
    profile.role != "staff" && (
      <Box className={classes.navBar}>
        <Box>
          <Box
            className={classes.navBarTopContainer}
            onClick={() => {
              history("profile");
            }}
          >
            <Avatar>
              {user.given_name ? user.given_name[0] : ""}
              {user.family_name ? user.family_name[0] : ""}
            </Avatar>
            <Typography variant="h3">{user.given_name}</Typography>
          </Box>
        </Box>
        <Box className={classes.menuItemsContainer}>
          <Box
            className={classes.navItem}
            onClick={() => history("/dashboard")}
          >
            <Box className={classes.iconContainer}>
              <SpeedIcon color="primary" />
            </Box>
            <Typography variant="h2">Dashboard</Typography>
          </Box>

          <Box className={classes.navItem} onClick={() => history("people")}>
            <Box className={classes.iconContainer}>
              <PeopleIcon color="primary" />
            </Box>
            <Typography variant="h2">People</Typography>
          </Box>

          <Box className={classes.navItem} onClick={() => history("questions")}>
            <Box className={classes.iconContainer}>
              <QuizIcon color="primary" />
            </Box>
            <Typography variant="h2">Questions</Typography>
          </Box>
          {/* {profile.accountType === "sports" ? null : ( */}
          <Box className={classes.navItem} onClick={() => history("bookings")}>
            <Box className={classes.iconContainer}>
              <BookIcon color="primary" />
            </Box>
            <Typography variant="h2">Bookings</Typography>
          </Box>
          {/* )} */}
        </Box>
        <Box className={classes.navBarBottomContainer}></Box>
      </Box>
    )
  );
};

export default NavBar;
