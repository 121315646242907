import { personPageStyles } from "./pagesStyles";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PersonDetails from "../hrDashboardComponents/personComponents/PersonDetails";
import { getPersonDetails } from "../apiHelper";
import OtherComments from "../hrDashboardComponents/personComponents/OtherComments";
import StatusComponent from "../personPageComponents/StatusComponent";
import CommentAnswers from "../hrDashboardComponents/personComponents/CommentAnswers";
import PersonParticipationTrend from "../hrDashboardComponents/personComponents/PersonParticipationTrend";
import PersonMoodTrend from "../hrDashboardComponents/personComponents/PersonMoodTrend";
import { DateRangeSelector } from "../hrDashboardComponents/DateRangeSelector";

function Person() {
  const classes = personPageStyles();
  const { user, getAccessTokenSilently } = useAuth0();

  const [person, setPerson] = useState({});
  const [range, setRange] = useState(new Date());
  const params = useParams();

  useEffect(() => {
    if (user) {
      getAccessTokenSilently().then((t) => {
        getPersonDetails(t, {
          personId: params.personId,
          today: Date(),
        }).then((pr) => {
          console.log(pr);
          setPerson(pr);
        });
      });
    }
  }, [user]);

  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.headingBar}>
        <Typography variant="h1">
          {person.firstName} {person.lastName}
        </Typography>
      </Box>
      <Box className={classes.personalRow}>
        <PersonDetails person={person} />
      </Box>
      <StatusComponent
        scoreToday={person.scoreToday}
        monthlyAverage={person.monthlyAverage}
        participation={person.participation}
        trend={person.trend}
      />
      <Box className={classes.subHeadingBar}>
        <Typography variant="h1">Statistics</Typography>
        <DateRangeSelector range={range} setRange={setRange} />
      </Box>
      <Box className={classes.answersContainer}>
        {person._id ? (
          <PersonParticipationTrend range={range} personId={person._id} />
        ) : null}
        {person._id ? (
          <PersonMoodTrend range={range} personId={person._id} />
        ) : null}
      </Box>
      <Box className={classes.answersContainer}>
        {person._id ? (
          <CommentAnswers personId={person._id} range={range} />
        ) : null}
        {person._id ? (
          <OtherComments personId={person._id} range={range} />
        ) : null}
      </Box>
    </Box>
  );
}

export default Person;
