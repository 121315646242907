import { Box, Typography } from "@mui/material";
import { commentItemStyles } from "./personUserStyles";
import dayjs from "dayjs";

const CommentItem = props => {
  const { comment } = props;
  const classes = commentItemStyles();
  return (
    <Box className={classes.outerContainer}>
      {comment.value ? (
        <Typography variant="body1">{comment.value}</Typography>
      ) : (
        <Typography variant="body1">No Comment</Typography>
      )}
      <Typography variant="body2">
        {dayjs(comment.createdAt).format("DD MMM YYYY  h:mma")}
      </Typography>
    </Box>
  );
};

export default CommentItem;
