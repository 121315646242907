import { useLocation } from "react-router-dom";
import { successPageStyles } from "./pagesStyles";
import { Box, Typography } from "@mui/material";

function NotYetSetup() {
  const classes = successPageStyles();
  const location = useLocation();

  return (
    <Box className={classes.backdrop}>
      <Box className={classes.outerContainer}>
        <Typography variant="h1">
          Your administrator has not yet completed setup. Check back later!
        </Typography>
        <Typography variant="body1">You can close this tab now.</Typography>
      </Box>
    </Box>
  );
}

export default NotYetSetup;
