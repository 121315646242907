import { useLocation, useNavigate } from "react-router-dom";
import { commentSuccessPageStyles } from "./pagesStyles";
import { Box, Button, Typography } from "@mui/material";

function CommentSuccess() {
  const classes = commentSuccessPageStyles();
  const history = useNavigate();
  const location = useLocation();

  return (
    <Box className={classes.backdrop}>
      <Box className={classes.outerContainer}>
        <Typography variant="h1">Thank you for talking to us.</Typography>
        <Typography variant="h1">
          We'll be in touch soon as soon as possible as required.
        </Typography>
        <Box className={classes.buttonContainer}>
          <Button
            sx={{
              textTransform: "none",
              marginLeft: "0.4rem",
              borderRadius: "20px",
              backgroundColor: "#A7C4A1",
              height: "2rem"
            }}
            color="primary"
            variant="contained"
            onClick={() => history("/")}
          >
            <Typography variant="body1">Continue</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default CommentSuccess;
