import { Box, Typography } from "@mui/material";
import { participationTrendStyle } from "./personUserStyles";
import { useEffect, useState } from "react";
import { getGraphData } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { ResponsiveLine } from "@nivo/line";

const PersonParticipationTrend = props => {
  const classes = participationTrendStyle();
  const { range, personId } = props;
  const { user, getAccessTokenSilently } = useAuth0();

  const [graphData, setGraphData] = useState([
    { x: "monday", y: 0 },
    { x: "tuesday", y: 0 },
    { x: "wednesday", y: 0 },
    { x: "thursday", y: 0 },
    { x: "friday", y: 0 },
    { x: "saturday", y: 0 },
    { x: "sunday", y: 0 }
  ]);

  const data = [
    {
      id: "data",
      color: "hsl(348, 70%, 50%)",
      data: graphData
    }
  ];

  useEffect(() => {
    getAccessTokenSilently().then(t =>
      getGraphData(t, {
        personId,
        graphType: "person-participation-trend",
        range: range
      }).then(data => {
        setGraphData(data.dayData.finalData);
      })
    );
  }, [range]);

  return (
    <Box className={classes.outer}>
      <Typography variant="h2">Participation Trend</Typography>
      <Box className={classes.inner}>
        <Box className={classes.informationOuter}>
          <ResponsiveLine
            data={data}
            colors={"#9FC59E"}
            enableGridX={false}
            pointSize={1}
            enableCrosshair={true}
            gridYValues={4}
            axisLeft={{
              tickValues: 4
            }}
            pointColor={"#9FC59E"}
            margin={{ top: 15, right: 25, bottom: 50, left: 25 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "0",
              max: "10"
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PersonParticipationTrend;
