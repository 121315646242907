import { Box, Checkbox, Typography } from "@mui/material";
import { availableDays } from "./bookingsComponentStyles";
import TimeRangeSelectorComponent from "./TimeRangeSelectorComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { uppercaseFirstLetter } from "../../helperFunctions";

const SettingsAvailableTimesComponent = props => {
  const classes = availableDays();
  const { availableData, changeAvailableData, day, setChanged } = props;
  return (
    <Box className={classes.outer}>
      <Box className={classes.dayBox}>
        <Checkbox
          checked={availableData.active.includes(day)}
          onChange={e => {
            if (e.target.checked) {
              changeAvailableData({
                ...availableData,
                active: [...availableData.active, day]
              });
              setChanged(true);
            } else {
              changeAvailableData({
                ...availableData,
                active: availableData.active.filter(d => d !== day)
              });
              setChanged(true);
            }
          }}
        />
        <Typography variant="h4">{uppercaseFirstLetter(day)}</Typography>
      </Box>
      <Box className={classes.timeBox}>
        {availableData[day].length > 0 ? (
          <Box>
            {availableData[day].map((slot, i) => (
              <TimeRangeSelectorComponent
                index={i}
                key={i}
                day={day}
                changeAvailableData={changeAvailableData}
                availableData={availableData}
                slot={slot}
                setChanged={setChanged}
              />
            ))}
          </Box>
        ) : (
          <Typography variant="body2" color={"#999999"}>
            No available slots on this day.
          </Typography>
        )}
      </Box>
      <Box
        className={classes.addBox}
        onClick={() => {
          changeAvailableData({
            ...availableData,
            [day]: [...availableData[day], { from: null, to: null }]
          });
        }}
      >
        <AddCircleOutlineIcon />
        <Typography variant="h4">Add Available Slots</Typography>
      </Box>
    </Box>
  );
};

export default SettingsAvailableTimesComponent;
