import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getMyAnswers } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import CommentItem from "./CommentItem";
import { commentAnswersStyles } from "./personUserStyles";
import { NoCommentItem } from "../../components/NoCommentItem";

const CommentAnswers = props => {
  const { personId, range } = props;
  const { user, getAccessTokenSilently } = useAuth0();
  const classes = commentAnswersStyles();
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    getAccessTokenSilently().then(t =>
      getMyAnswers(t, { personId, range }).then(answers => {
        setAnswers(answers.answers);
      })
    );
  }, [range]);
  return (
    <Box className={classes.outerContainer}>
      <Typography variant={"h2"}>Answers</Typography>
      <Box className={classes.innerContainer}>
        {answers.length > 0 ? (
          answers.map((c, i) => <CommentItem comment={c} key={i} />)
        ) : (
          <NoCommentItem />
        )}
      </Box>
    </Box>
  );
};

export default CommentAnswers;
