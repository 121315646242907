import { Box, Typography } from "@mui/material";
import { questionComponentHeadingStyles } from "./hrComponentsStyles";

const QuestionHeading = () => {
  const classes = questionComponentHeadingStyles();

  return (
    <Box className={classes.outer}>
      <Box className={classes.iconContainer}></Box>
      <Box className={classes.center}></Box>
      <Box className={classes.iconSuffixType}>
        <Typography variant="h5">Type</Typography>
      </Box>
      <Box className={classes.iconSuffix}>
        <Typography variant="h5">Active</Typography>
      </Box>
    </Box>
  );
};

export default QuestionHeading;
