import { bookingsPageStyles } from "./pagesStyles";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import BookingsList from "../hrDashboardComponents/bookingsComponents/BookingsList";
import BookingsSettings from "../hrDashboardComponents/bookingsComponents/BookingsSettings";

function Bookings() {
  const classes = bookingsPageStyles();

  const [tab, setTab] = useState("bookings");

  return (
    <Box className={classes.outerContainer}>
      <Typography variant="h1">Bookings</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
          <Tab value="bookings" label="Bookings" />
          <Tab value="settings" label="Settings" />
        </Tabs>
      </Box>
      {tab === "bookings" ? <BookingsList /> : <BookingsSettings />}
    </Box>
  );
}

export default Bookings;
