import { Box, Button, Modal, Typography } from "@mui/material";
import { modalStyles } from "../../pages/pagesStyles";
import { removeQuestionDate } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";

const DeleteSpecificDateItemModal = (props) => {
  const {
    modal,
    openModal,
    questionId,
    setDatedQuestionsAdded,
    datedQuestionsAdded,
  } = props;
  const { getAccessTokenSilently } = useAuth0();
  const modalClasses = modalStyles();
  return (
    <Modal open={modal} onClose={() => openModal(false)}>
      <Box className={modalClasses.modalBase}>
        <Typography>Are you sure you want to delete this question?</Typography>
        <Box className={modalClasses.buttonRow}>
          <Button
            variant="contained"
            onClick={() => {
              getAccessTokenSilently().then((t) => {
                removeQuestionDate(t, { questionId });
                setDatedQuestionsAdded(!datedQuestionsAdded);
                openModal(false);
              });
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              getAccessTokenSilently().then((t) => {
                openModal(false);
              });
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteSpecificDateItemModal;
