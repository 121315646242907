import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { rescheduleModalStyles } from "./bookingsComponentStyles";
import { useState } from "react";
import { updateCancelBooking } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { Cancel, CheckCircle } from "@mui/icons-material";

const CancelModal = (props) => {
  const { modalOpen, openModal, bookingId, bookingMakerName, recipientEmail } =
    props;
  const { getAccessTokenSilently, user } = useAuth0();
  const [bookingData, changeBookingData] = useState({});
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [cancelResponse, setCancelResponse] = useState({});
  const handleCancel = () => {
    setLoading(true);
    setClicked(true);
    getAccessTokenSilently().then((t) => {
      updateCancelBooking(t, bookingId, {
        ...bookingData,
        bookingMakerName,
        recipientEmail,
        providerName: user.given_name,
      }).then((data) => {
        setLoading(false);
        setCancelResponse(data);
      });
    });
  };

  return (
    <Modal open={modalOpen} onClose={() => openModal(false)}>
      {clicked ? (
        <ConfirmCancel
          loading={loading}
          openModal={openModal}
          cancelResponse={cancelResponse}
          setClicked={setClicked}
          setCancelResponse={setCancelResponse}
        />
      ) : (
        <ModalContent
          openModal={openModal}
          handleReschedule={handleCancel}
          bookingData={bookingData}
          changeBookingData={changeBookingData}
        />
      )}
    </Modal>
  );
};

const ModalContent = (props) => {
  const { openModal, handleReschedule } = props;

  const modalClasses = rescheduleModalStyles();

  return (
    <Box className={modalClasses.modalBase}>
      <Typography variant="body1">
        Are you sure you want to cancel this booking?
      </Typography>
      <Box className={modalClasses.buttonRow}>
        <Button
          onClick={() => {
            handleReschedule();
          }}
        >
          Confirm
        </Button>
        <Button onClick={() => openModal(false)}>Cancel</Button>
      </Box>
    </Box>
  );
};

const ConfirmCancel = (props) => {
  const modalClasses = rescheduleModalStyles();
  const { loading, cancelResponse, openModal, setClicked, setCancelResponse } =
    props;
  return (
    <Box className={modalClasses.modalBase}>
      <Box className={modalClasses.progressContainer}>
        {loading ? (
          <CircularProgress />
        ) : cancelResponse.cancelledEmailSent ? (
          <CheckCircle />
        ) : (
          <Cancel />
        )}
      </Box>
      <Typography variant="body1">
        {loading
          ? "Cancelling"
          : cancelResponse.rescheduleEmailSent
          ? "Cancel confirmation email sent!"
          : "Unable to cancel. Please try again!"}
      </Typography>
      <Box className={modalClasses.buttonRow}>
        <Button
          sx={{ textTransform: "none" }}
          color="primary"
          onClick={() => {
            openModal(false);
            setClicked(false);
            setCancelResponse({});
          }}
        >
          {loading ? null : <Typography variant="body2">Done</Typography>}
        </Button>
      </Box>
    </Box>
  );
};

export default CancelModal;
