import { Avatar, Box, Button, Typography } from "@mui/material";
import { profileUserDetailsStyles } from "./profileUserStyles";
import { convertRoleType } from "../../helperFunctions";

const ProfileUserDetails = props => {
  const classes = profileUserDetailsStyles();
  const { userDetails, logout } = props;

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_REDIRECT_URL || "http://localhost:3000/"
      }
    });
  };
  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.nameAndPictureContainer}>
        <Avatar classes={{ root: classes.iconContainerAvatar }} />
        <Box className={classes.innerText}>
          <Box className={classes.nameText}>
            <Typography variant="h2">
              {userDetails.firstName} {userDetails.lastName}
            </Typography>
            <Typography variant="h3">
              {convertRoleType(userDetails.role ? userDetails.role : "")}
            </Typography>
          </Box>
        </Box>
        <Button onClick={handleLogout}>Log out</Button>
      </Box>
    </Box>
  );
};

export default ProfileUserDetails;
