import { helpContainerComponentStyles } from "./componentsStyles";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";

const SkipHelpComponent = props => {
  const classes = helpContainerComponentStyles();
  const {} = props;

  return (
    <Box className={classes.skipContainerOuter}>
      <Typography variant="body1">
        If you would prefer to reach out in your own time or to your own support
        network, feel free to skip this section.
      </Typography>
      <Box className={classes.skipSubmitRowContainer}>
        <Button variant="outlined">Skip</Button>
      </Box>
    </Box>
  );
};

export default SkipHelpComponent;
