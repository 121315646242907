import {
  Autocomplete,
  Box,
  Modal,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import SettingsDatePicker from "./SettingsDatePicker";
import { modalStyles } from "../../pages/pagesStyles";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { addDatedQuestion, searchQuestions } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";

const SpecificDateModal = (props) => {
  const { modal, openModal, datedQuestionsAdded, setDatedQuestionsAdded } =
    props;
  const modalClasses = modalStyles();
  const [selectedDate, changeSelectedDate] = useState(dayjs(new Date()));
  const [selectedQuestion, changeSelectedQuestion] = useState();
  const [options, setOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getData = async () => {
      const data = await searchQuestions({
        keyword: keyword,
        externalId: user.sub,
      });
      setOptions(data.questions);
      changeSelectedQuestion(options[0]);
    };
    getData();
  }, [keyword]);

  useEffect(() => {
    if (!dropdownOpen) {
      setOptions([]);
    }
  }, [dropdownOpen]);

  const handleSubmit = () => {
    getAccessTokenSilently().then((t) => {
      addDatedQuestion(t, {
        date: selectedDate,
        questionId: selectedQuestion.id,
      }).then(() => {
        setDatedQuestionsAdded(!datedQuestionsAdded);
        openModal(false);
      });
    });
  };

  return (
    <>
      <Modal open={modal} onClose={() => openModal(false)}>
        <Box className={modalClasses.modalBase}>
          <Typography variant="h1">Set question for a date</Typography>
          <Box className={modalClasses.questionSelector}>
            <Typography variant="h5">Select a question</Typography>
            <Autocomplete
              disablePortal
              clearIcon={false}
              onChange={(c, v) => {
                changeSelectedQuestion(v);
              }}
              value={selectedQuestion}
              options={options.map((option, i) => {
                return { label: option.question, id: option._id, key: i };
              })}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
              )}
            />
          </Box>
          <Box className={modalClasses.typeSelector}>
            <SettingsDatePicker
              selectedDate={selectedDate}
              changeSelectedDate={changeSelectedDate}
            />
          </Box>

          <Box className={modalClasses.buttonRow}>
            <Button onClick={() => openModal(false)}>Cancel</Button>

            <Button
              variant="contained"
              onClick={() => {
                handleSubmit();
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SpecificDateModal;
