import { Box, Checkbox, MenuItem, Select, Typography } from "@mui/material";
import { questionComponentStyles } from "./hrComponentsStyles";
import MessageIcon from "@mui/icons-material/Message";
import ScoreIcon from "@mui/icons-material/Score";
import Avatar from "@mui/material/Avatar";
import { updateQuestion, getQuestions } from "../apiHelper";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const determineIcon = iconType => {
  if (iconType === "rating") {
    return <ScoreIcon color="white" />;
  }
  if (iconType === "comments") {
    <MessageIcon color="white" />;
  }
  return null;
};

const QuestionComponent = props => {
  const classes = questionComponentStyles();
  const { questionText, active, day, id, questionType, updateQuestionList } =
    props;
  const [localType, setLocalType] = useState(questionType);
  const [localDay, setLocalDay] = useState(day);
  const [localActive, setLocalActive] = useState(active);
  const { user } = useAuth0();
  const handleChange = (type, value) => {
    let data = {
      question: questionText,
      type: localType,
      active,
      day: localDay,
      requestingUserId: user.sub
    };
    data[type] = value;
    updateQuestion(null, id, data).then(() => {
      getQuestions({ externalId: user.sub }).then(q => {
        updateQuestionList(q.questions);
      });
    });
  };
  return (
    <Box className={classes.outer}>
      <Box className={classes.iconContainer}>
        <Avatar classes={{ root: classes.iconContainerAvatar }}>
          {determineIcon(localType)}
        </Avatar>
      </Box>
      <Box className={classes.notificationBody}>
        <Typography variant="body">{questionText}</Typography>
      </Box>
      <Box className={classes.iconSuffixType}>
        <Typography variant="h5">
          {" "}
          <Select
            variant="standard"
            onChange={e => {
              setLocalType(e.target.value);
              handleChange("type", e.target.value);
            }}
            value={localType}
            disableUnderline={true}
            MenuProps={{ disablePortal: true }}
          >
            <MenuItem value="rating">Rating</MenuItem>
            <MenuItem value="comments">Comments</MenuItem>
          </Select>
        </Typography>
      </Box>
      <Box className={classes.iconSuffix}>
        <Typography variant="h5">
          <Checkbox
            checked={localActive}
            onChange={e => {
              setLocalActive(e.target.checked);
              handleChange("active", e.target.checked);
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default QuestionComponent;
