import { Avatar, Box, Button, Modal, Typography } from "@mui/material";
import { personComponentStyles } from "./hrComponentsStyles";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import { deactivateUser } from "../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import EditPersonModalComponent from "./EditPersonModalComponent";

const PersonComponent = props => {
  const classes = personComponentStyles();
  const { person, managers } = props;
  const navigate = useNavigate();
  const { user, getAccessTokenSilently } = useAuth0();
  const changeUrl = (navigate, personId) => {
    navigate(`${personId}`, { state: { personId } });
  };

  const [modalOpen, openModal] = useState(false);
  const [editModalOpen, setEditModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = event => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const determineColor = participation => {
    if (participation === "Low") {
      return classes.lowParticipation;
    }
    if (participation === "Average") {
      return classes.averageParticipation;
    }
    if (participation === "High") {
      return classes.highParticipation;
    }
  };

  return (
    <Box className={classes.outer}>
      <Box
        className={classes.left}
        onClick={() => changeUrl(navigate, person._id)}
      >
        <Avatar></Avatar>
        <Typography className={classes.name}>
          {person.firstName} {person.lastName}
        </Typography>
      </Box>
      <Box className={classes.right}>
        <Box className={`${classes.statisticsButton}`}>
          <Typography>{person.lastAnswered}</Typography>
        </Box>
        <Box
          className={`${classes.statisticsButton} ${determineColor(
            person.participation
          )}`}
        >
          <Typography>{person.participation}</Typography>
        </Box>
        <MoreHorizIcon
          style={{ color: "#747474", cursor: "pointer" }}
          onClick={handleClick}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          sx={{ padding: 0 }}
        >
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              setEditModal(true);
            }}
          >
            <Box className={classes.hamburgerMenuItem}>
              <EditIcon
                fontSize="inherit"
                sx={{ paddingBottom: 0.4, marginRight: 1 }}
              />
              <Typography variant="body2">Edit</Typography>
            </Box>
          </MenuItem>
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              openModal(true);
            }}
          >
            <Box className={classes.hamburgerMenuItem}>
              <CancelIcon
                fontSize="inherit"
                sx={{ paddingBottom: 0.4, marginRight: 1 }}
              />
              <Typography variant="body2">Deactivate</Typography>
            </Box>
          </MenuItem>
        </Menu>
      </Box>
      <EditPersonModalComponent
        addModalOpen={editModalOpen}
        openModal={setEditModal}
        person={person}
        currentProfile={user}
        filteredPeople={managers}
      />
      <Modal
        open={modalOpen}
        onClose={e => {
          e.stopPropagation();
          openModal(false);
        }}
      >
        <Box className={classes.deactivateModal}>
          <Box className={classes.modalInner}>
            <Typography>
              Are you sure you want to deactivate this user?
            </Typography>
            <Box className={classes.modalButtonContainer}>
              <Button
                onClick={e => {
                  e.stopPropagation();
                  openModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={e => {
                  e.stopPropagation();
                  getAccessTokenSilently().then(t => {
                    deactivateUser(t, {
                      userId: person._id,
                      externalId: user.sub
                    }).then(() => {
                      openModal(false);
                    });
                  });
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PersonComponent;
