import { useLocation, useNavigate } from "react-router-dom";
import { successPageStyles } from "./pagesStyles";
import { Box, Button, Typography } from "@mui/material";

function Success() {
  const classes = successPageStyles();
  const location = useLocation();
  const history = useNavigate();
  return (
    <Box className={classes.backdrop}>
      <Box className={classes.outerContainer}>
        <Typography variant="h1">
          {location.state && location.state.alreadyAnswered
            ? "You've already answered today! See you tomorrow."
            : "That's all for today! See you tomorrow."}
        </Typography>
        <Typography variant="body1">You can close this tab now.</Typography>
        <Box className={classes.somethingElseContainer}>
          <Button
            sx={{
              textTransform: "none",
              marginLeft: "0.4rem",
              borderRadius: "20px",
              color: "white",
              backgroundColor: "#A7C4A1"
            }}
            color="primary"
            variant="contained"
            onClick={() =>
              history("/something-else", {
                state: {
                  from: "scoreCard"
                }
              })
            }
          >
            <Typography variant="body1">
              I want to talk about something else
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Success;
