import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getComments } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import CommentItem from "./CommentItem";
import { otherCommentStyles } from "./personUserStyles";
import { NoCommentItem } from "../../components/NoCommentItem";

const OtherComments = props => {
  const { personId, range } = props;
  const { user, getAccessTokenSilently } = useAuth0();
  const classes = otherCommentStyles();
  const [comments, setComments] = useState([]);
  useEffect(() => {
    getAccessTokenSilently().then(t =>
      getComments(t, { personId, range }).then(comments => {
        setComments(comments.data);
      })
    );
  }, [range]);
  return (
    <Box className={classes.outerContainer}>
      <Typography variant={"h2"}>Other Comments</Typography>
      <Box className={classes.innerContainer}>
        {comments.length > 0 ? (
          comments.map((c, i) => <CommentItem comment={c} key={i} />)
        ) : (
          <NoCommentItem />
        )}
      </Box>
    </Box>
  );
};

export default OtherComments;
