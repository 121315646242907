import { ratingsStyles } from "./componentsStyles";
import { Box, TextField, Typography } from "@mui/material";

const RatingsComponent = props => {
  const classes = ratingsStyles();
  const { points, onClick, score, questionData, setValue } = props;
  if (questionData.type === "rating") {
    return (
      <Box className={classes.ratingsContainer}>
        <Typography variant="h5">1</Typography>
        {[...Array(points)].map((e, i) => {
          return (
            <Box className={classes.iconContainer} key={i}>
              <Box
                className={
                  i === 0
                    ? `${classes.lineSegment} ${classes.firstLast}`
                    : classes.lineSegment
                }
              ></Box>
              <Box className={classes.checkBoxContainer}>
                <Box
                  className={classes.checkBox}
                  onClick={() => onClick(i + 1)}
                >
                  {i === score - 1 ? (
                    <Box className={classes.insideBox}>
                      <Box className={classes.innerInsideBox}></Box>
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <Box
                className={
                  i === points - 1
                    ? `${classes.lineSegment} ${classes.firstLast}`
                    : classes.lineSegment
                }
              ></Box>
            </Box>
          );
        })}
        <Typography variant="h5">{points}</Typography>
      </Box>
    );
  }
  return (
    <Box className={classes.ratingsContainer}>
      <TextField
        id="outlined-multiline-flexible"
        // label="Multiline"
        placeholder="Let us know how you feel! You can send this through anonymously if it makes you feel more comfortable."
        fullWidth
        multiline
        rows={4}
        onChange={e => setValue(e.target.value)}
      />
    </Box>
  );
};

export default RatingsComponent;
