import {
  Avatar,
  Box,
  Button,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  modalStyles,
  questionsSettingsLayoutStyles,
} from "../pages/pagesStyles";
import DayOfWeekComponent from "./DayOfWeekComponent";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";

import SpecificDateModal from "./questionsComponents/SpecificDateModal";
import SpecificDateItem from "./questionsComponents/SpecificDateItem";

const QuestionSettingsLayout = (props) => {
  const classes = questionsSettingsLayoutStyles();
  const {
    setSettings,
    questionType,
    questions,
    datedQuestions,
    setDatedQuestionsAdded,
    datedQuestionsAdded,
  } = props;
  const [modal, openModal] = useState(false);

  const [localQuestions, setLocalQuestions] = useState({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
  });

  return (
    <>
      <Box className={classes.outerContainer}>
        {" "}
        <Box className={classes.sectionContainer}>
          <Box className={classes.sectionHeading}>
            <Typography variant="h3">Options</Typography>
          </Box>
          <Box className={classes.selectContainerOuter}>
            <Typography variant="h2">
              How would you like questions to be asked?
            </Typography>
            <Box className={classes.selectContainer}>
              <Select
                variant="standard"
                onChange={(e) => {
                  setSettings({ type: e.target.value });
                }}
                value={questionType}
                disableUnderline={true}
                MenuProps={{ disablePortal: true }}
              >
                <MenuItem value="random">
                  <Box className={classes.selectOptionInner}>
                    <Avatar classes={{ root: classes.iconContainerAvatar }}>
                      <ShuffleIcon sx={{ fontSize: "1rem" }} />
                    </Avatar>

                    <Typography variant="body1">Randomise questions</Typography>
                  </Box>
                </MenuItem>
                <MenuItem value="set">
                  <Box className={classes.selectOptionInner}>
                    <Avatar classes={{ root: classes.iconContainerAvatar }}>
                      <ViewWeekIcon sx={{ fontSize: "1rem" }} />
                    </Avatar>
                    <Typography variant="body1">
                      Set question for each day of the week
                    </Typography>
                  </Box>
                </MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>
        <Box className={classes.sectionContainer}>
          <Box className={classes.sectionHeading}>
            <Typography variant="h3">Specific Day</Typography>
            <Button
              onClick={() => openModal(true)}
              sx={{
                // borderRadius: "50%",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 0,
                height: "1.8rem",
                width: "1.8rem",
                backgroundColor: "#A7C4A1",
                paddingTop: "8px",
                fontSize: "1.2rem",
                borderRadius: "50%",
                marginLeft: "0.8rem",
              }}
              variant="contained"
            >
              <Box className={classes.sectionButtonInner}>+</Box>
            </Button>
          </Box>
          {datedQuestions.length > 0 && (
            // <Box className={classes.selectContainerOuter}>
            <>
              {datedQuestions.map((questionDetails) => (
                <SpecificDateItem
                  questionDetails={questionDetails}
                  setDatedQuestionsAdded={setDatedQuestionsAdded}
                  datedQuestionsAdded={datedQuestionsAdded}
                />
              ))}
            </>
            // </Box>
          )}
        </Box>
        {questionType === "set" && (
          <Box className={classes.sectionContainer}>
            <Box className={classes.sectionHeading}>
              <Typography variant="h3">Days of Week</Typography>
            </Box>
            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((v) => (
              <DayOfWeekComponent
                questionType={questionType}
                dayOfWeek={v}
                activeQuestions={questions.filter((q) => q.active === true)}
                localQuestions={localQuestions}
                setLocalQuestions={setLocalQuestions}
              />
            ))}
          </Box>
        )}
      </Box>
      {modal && (
        <SpecificDateModal
          modal={modal}
          openModal={openModal}
          setDatedQuestionsAdded={setDatedQuestionsAdded}
          datedQuestionsAdded={datedQuestionsAdded}
        />
      )}
    </>
  );
};

export default QuestionSettingsLayout;
