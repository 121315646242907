import axios from "axios";

export const convertRoleType = (name) => {
  switch (name) {
    case "administrators":
      return "Administrator";
    case "managers":
      return "Manager";
    case "staff":
      return "Staff";
    default:
      return;
  }
};

export const convertToDayJsWeekNumber = (name) => {
  switch (name) {
    case 0:
      return "sunday";
    case 1:
      return "monday";
    case 2:
      return "tuesday";
    case 3:
      return "wednesday";
    case 4:
      return "thursday";
    case 5:
      return "friday";
    case 6:
      return "saturday";
    default:
      return;
  }
};

export const uppercaseFirstLetter = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

export const bookingInterpreter = (anon, name) => {
  if (anon) {
    return "Anonymous Booking";
  }
  return name;
};
