import { Box, Typography } from "@mui/material";
import { personUserDetailsStyles } from "./personUserStyles";
import { convertRoleType } from "../../helperFunctions";
import CircleIcon from "@mui/icons-material/Circle";

const PersonDetails = props => {
  const classes = personUserDetailsStyles();
  const { person } = props;

  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.detailUnit}>
        <Typography variant="body1">
          {person.role ? convertRoleType(person.role) : ""}
        </Typography>
      </Box>
      <Box className={classes.dot}>
        <CircleIcon sx={{ fontSize: "4px" }} />
      </Box>
      <Box className={classes.detailUnit}>
        <Typography variant="body1">
          Last answered on {person.lastAnswered}
        </Typography>
      </Box>
    </Box>
  );
};

export default PersonDetails;
