import { StaticDatePicker } from "@mui/x-date-pickers";

const SettingsDatePicker = props => {
  const { selectedDate, changeSelectedDate } = props;
  return (
    <>
      <StaticDatePicker
        onChange={d => {
          changeSelectedDate(d);
        }}
        value={selectedDate}
        componentsProps={{
          actionBar: {
            actions: []
          }
        }}
      />
    </>
  );
};

export default SettingsDatePicker;
