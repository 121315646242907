import { Box, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import dayjs from "dayjs";
import * as isoWeek from "dayjs/plugin/isoWeek";
import { hrOverviewStyles } from "../pages/pagesStyles";
dayjs.extend(isoWeek);

export const DateRangeSelector = props => {
  const { setRange, range } = props;
  const classes = hrOverviewStyles();
  return (
    <Box className={classes.dataRangeSelector}>
      <Box className={classes.arrowContainer}>
        <KeyboardArrowLeftIcon
          color="secondary"
          onClick={() => setRange(dayjs(range).subtract(1, "week"))}
        />
      </Box>
      <Typography variant="h4">
        {dayjs(range).startOf("isoWeek").format("D MMM")} -{" "}
        {dayjs(range).endOf("isoWeek").format("D MMM")}
      </Typography>
      <Box className={classes.arrowContainer}>
        <KeyboardArrowRightIcon
          color="secondary"
          onClick={() => setRange(dayjs(range).add(1, "week"))}
        />
      </Box>
    </Box>
  );
};
