import { Box, Typography } from "@mui/material";
import { bookingsSettings } from "./bookingsComponentStyles";
import { Button } from "antd";
import { useEffect, useState } from "react";
import SettingsAvailableTimesComponent from "./SettingsAvailableTimesComponent";
import { getAvailableTimes, updateAvailableData } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";

const BookingsSettings = props => {
  const classes = bookingsSettings();
  const { user, getAccessTokenSilently } = useAuth0();
  const {} = props;
  const [changed, setChanged] = useState(false);

  const [availableData, changeAvailableData] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
    active: []
  });

  const [loaded, setLoaded] = useState(false);
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ];

  useEffect(() => {
    getAccessTokenSilently().then(t => {
      getAvailableTimes(t, { externalId: user.sub })
        .then(a => {
          changeAvailableData(a);
        })
        .finally(() => setLoaded(true));
    });
  }, []);

  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.selectSlotFormContainer}>
        <Box className={classes.availableHoursTitle}>
          <Typography variant="h2">Select your available hours</Typography>
        </Box>
        {loaded
          ? daysOfWeek.map((d, i) => (
              <SettingsAvailableTimesComponent
                key={i}
                day={d}
                availableData={availableData}
                changeAvailableData={changeAvailableData}
                setChanged={setChanged}
              />
            ))
          : null}
      </Box>
      <Box className={classes.saveContainer}>
        <Button
          onClick={() => {
            getAccessTokenSilently().then(t => {
              updateAvailableData(t, user.sub, availableData);
            });
          }}
          disabled={!changed}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default BookingsSettings;
