import { Box, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import { summaryLineStyles } from "./dashboardComponentStyles";

const SummaryLine = (props) => {
  const { type, title } = props;
  const classes = summaryLineStyles();
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(167, 196, 161,0.4)",
        borderColor: "#A7C4A1",
      },
      {
        label: "Second dataset",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#017AFE",
        fill: true,
        backgroundColor: "rgba(1, 122, 254,0.1)",
      },
    ],
  };

  return (
    <Box
      className={
        type === "grow" ? classes.outerContainer : classes.staticOuterContainer
      }
    >
      <Typography className={classes.title}>{title}</Typography>
      <Line
        data={data}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: false,
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default SummaryLine;
