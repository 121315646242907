import { helpContainerComponentStyles } from "./componentsStyles";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Box, Typography } from "@mui/material";
import AvailableTimesComponent from "./AvailableTimesComponent";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAvailableTimesClient } from "../apiHelper";
import dayjs from "dayjs";
import { convertToDayJsWeekNumber } from "../helperFunctions";
import UnavailableComponent from "./UnavailableComponent";

const HelpDateComponent = (props) => {
  const classes = helpContainerComponentStyles();
  const { bookingData, changeBookingData, suggest } = props;
  const { user, getAccessTokenSilently } = useAuth0();
  const [selectedDayOfWeek, changeSelectedDayOfWeek] = useState();

  const [availableSlots, changeAvailableSlots] = useState([]);
  const [selectedSlot, changeSelectedSlot] = useState(null);
  const [availableData, changeAvailableData] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
    active: [],
  });

  useEffect(() => {
    const dataToSend = suggest ? { externalId: user.sub } : null;
    getAccessTokenSilently().then((t) => {
      getAvailableTimesClient(t, dataToSend).then((a) => {
        changeAvailableData(a);
      });
    });
  }, []);

  useEffect(() => {
    changeAvailableSlots([]);
    if (selectedDayOfWeek) {
      let availableSlots = availableData[selectedDayOfWeek];
      availableData.bookedSlots.forEach((slot) => {
        if (
          dayjs(bookingData.bookingDate).isSame(dayjs(slot.bookingDate), "day")
        ) {
          availableSlots = availableSlots.filter(
            (as) => as !== slot.bookingTime
          );
        }
      });
      changeAvailableSlots(availableSlots);
    }
  }, [selectedDayOfWeek]);
  return (
    <Box className={classes.helpDateContainer}>
      {suggest ? (
        ""
      ) : (
        <Typography variant="h2">
          Book a session to discuss how we can help best
        </Typography>
      )}
      <Box className={classes.helpDateInner}>
        <StaticDatePicker
          onChange={(d) => {
            changeSelectedSlot(null);
            changeBookingData({
              ...bookingData,
              bookingDate: d,
            });
            changeSelectedDayOfWeek(convertToDayJsWeekNumber(dayjs(d).day()));
          }}
          shouldDisableDate={(day) =>
            !availableData.active.includes(
              convertToDayJsWeekNumber(dayjs(day).day())
            )
          }
          componentsProps={{
            actionBar: {
              actions: [],
            },
          }}
        />
        <Box className={classes.availableDatesContainer}>
          <Typography>Available Times</Typography>
          <Box className={classes.availableDatesListContainer}>
            {availableSlots.length > 0 ? (
              availableSlots.map((s, i) => {
                return (
                  <AvailableTimesComponent
                    index={i}
                    time={s}
                    selectedSlot={selectedSlot}
                    changeSelectedSlot={changeSelectedSlot}
                    bookingData={bookingData}
                    changeBookingData={changeBookingData}
                  />
                );
              })
            ) : (
              <UnavailableComponent />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpDateComponent;
