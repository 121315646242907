import { Box, Typography } from "@mui/material";
import { noBookingsComponent } from "./bookingsComponentStyles";

export const NoBookingsComponent = () => {
  const classes = noBookingsComponent();
  return (
    <Box className={classes.container}>
      <Typography variant="body1">No Bookings</Typography>
    </Box>
  );
};
