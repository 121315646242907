import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { specificDateItemStyles } from "./questionComponentStyles";
import dayjs from "dayjs";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteSpecificDateItemModal from "./DeleteSpecificDateItemModal";

const SpecificDateItem = (props) => {
  const classes = specificDateItemStyles();
  const { questionDetails, setDatedQuestionsAdded, datedQuestionsAdded } =
    props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModal, openDeleteModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };
  return (
    <>
      <Box className={classes.selectContainerOuter}>
        <Box className={classes.selectContainerInnerLeft}>
          <Typography variant="h2">
            {dayjs(questionDetails.date).format("DD MMMM YYYY")}
          </Typography>
          <Typography variant="body1">{questionDetails.question}</Typography>
        </Box>
        <Box className={classes.selectContainerInnerRight}>
          <MoreHorizIcon
            style={{ color: "#747474", cursor: "pointer" }}
            onClick={handleClick}
          />
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            sx={{ padding: 0 }}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                openDeleteModal(true);
              }}
            >
              <Box className={classes.hamburgerMenuItem}>
                <CancelIcon
                  fontSize="inherit"
                  sx={{ paddingBottom: 0.4, marginRight: 1 }}
                />
                <Typography variant="body2">Delete</Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {deleteModal && (
        <DeleteSpecificDateItemModal
          modal={deleteModal}
          openModal={openDeleteModal}
          questionId={questionDetails._id}
          setDatedQuestionsAdded={setDatedQuestionsAdded}
          datedQuestionsAdded={datedQuestionsAdded}
        />
      )}
    </>
  );
};

export default SpecificDateItem;
