import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useEffect, useState } from "react";
import { newPersonModalStyles } from "../pages/pagesStyles";
import { getManagers, updatePerson } from "../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { useOutletContext } from "react-router-dom";

const EditPersonModalComponent = (props) => {
  const modalClasses = newPersonModalStyles();

  const { person, addModalOpen, openModal } = props;

  const { profile } = useOutletContext();
  const { user, getAccessTokenSilently } = useAuth0();

  const [modalInfo, setModalInfo] = useState({
    role: person.role,
    email: person.email,
    firstName: person.firstName,
    lastName: person.lastName,
  });

  const [managers, setManagers] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const [selectedManager, setSelectedManager] = useState("-");

  useEffect(() => {
    if (addModalOpen && user) {
      setModalInfo({
        role: person.role,
        email: person.email,
        firstName: person.firstName,
        lastName: person.lastName,
        managedBy: person.managedBy,
      });

      getAccessTokenSilently().then((t) => {
        getManagers(t, { externalId: user.sub }).then((p) => {
          setManagers(p);
        });
      });
    }
  }, [addModalOpen]);
  useEffect(() => {
    findManager(person.managedBy);
  }, [managers]);

  const findManager = (managerId) => {
    const managerData = managers.find((o) => o._id === managerId);
    if (managerData) {
      setSelectedManager({
        label: `${managerData.firstName} ${managerData.lastName}`,
        value: managerId,
      });
    }
  };
  const handleUpdatePerson = () => {
    setSubmitButtonClicked(true);
    getAccessTokenSilently().then((t) => {
      updatePerson(t, {
        ...modalInfo,
        managedBy: modalInfo.managedBy ? modalInfo.managedBy.value : "",
        targetId: person._id,
        externalId: user.sub,
      })
        .then(() => {
          openModal(false);
          setSubmitButtonClicked(false);
        })
        .catch((e) => {
          setErrorMessage(e.response.data.message);
          setSubmitButtonClicked(false);
        });
    });
  };
  return (
    <Modal
      open={addModalOpen}
      onClose={(e) => {
        e.stopPropagation();
        openModal(false);
      }}
    >
      <Box className={modalClasses.modalBase}>
        <Typography variant="h1">Edit Person</Typography>
        <Box className={modalClasses.typeSelector}>
          <Select
            variant="standard"
            onChange={(e) =>
              setModalInfo({ ...modalInfo, role: e.target.value })
            }
            value={modalInfo.role}
            disableUnderline={true}
          >
            <MenuItem value="staff">
              {" "}
              <Box className={modalClasses.typeItemContainer}>
                <Avatar classes={{ root: modalClasses.iconContainerAvatar }}>
                  <PersonIcon color="white" fontSize="small" />
                </Avatar>
                Staff
              </Box>
            </MenuItem>
            <MenuItem value="managers">
              {" "}
              <Box className={modalClasses.typeItemContainer}>
                <Avatar classes={{ root: modalClasses.iconContainerAvatar }}>
                  <SupervisorAccountIcon color="white" fontSize="small" />
                </Avatar>
                Manager
              </Box>
            </MenuItem>
            {profile.role === "administrators" || profile.role === "hr" ? (
              <MenuItem value="administrators">
                <Box className={modalClasses.typeItemContainer}>
                  <Avatar classes={{ root: modalClasses.iconContainerAvatar }}>
                    <AdminPanelSettingsIcon color="white" fontSize="small" />
                  </Avatar>
                  Administrator
                </Box>
              </MenuItem>
            ) : null}
          </Select>
        </Box>
        <Box className={modalClasses.formRow}>
          <Box className={modalClasses.typeSelector}>
            <Typography>First Name</Typography>
            <TextField
              hiddenLabel
              placeholder="John"
              variant="outlined"
              value={modalInfo.firstName}
              onChange={(e) => {
                setModalInfo({ ...modalInfo, firstName: e.target.value });
              }}
            />
          </Box>
          <Box className={modalClasses.typeSelector}>
            <Typography>Last Name</Typography>
            <TextField
              hiddenLabel
              placeholder="Smith"
              variant="outlined"
              value={modalInfo.lastName}
              onChange={(e) => {
                setModalInfo({ ...modalInfo, lastName: e.target.value });
              }}
            />
          </Box>
        </Box>
        <Box className={modalClasses.typeSelector}>
          <Typography>Email</Typography>
          <TextField
            hiddenLabel
            fullWidth
            placeholder="john@smith.com"
            value={modalInfo.email}
            variant="outlined"
            onChange={(e) => {
              setModalInfo({ ...modalInfo, email: e.target.value });
            }}
          />
        </Box>
        {managers ? (
          <Box className={modalClasses.typeSelector}>
            <Typography>Managed by</Typography>
            <Autocomplete
              value={selectedManager}
              clearIcon={false}
              options={managers.map((m) => {
                return {
                  label: `${m.firstName} ${m.lastName}`,
                  value: m._id,
                };
              })}
              onChange={(e, v) => {
                setModalInfo({ ...modalInfo, managedBy: v });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Box>
        ) : null}
        <Box className={modalClasses.typeSelector}>
          <Typography variant="h4">{errorMessage}</Typography>
        </Box>
        <Box className={modalClasses.buttonRow}>
          <Button onClick={() => openModal(false)}>Cancel</Button>
          {submitButtonClicked ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                handleUpdatePerson();
              }}
            >
              Update
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EditPersonModalComponent;
